import axiosBase from 'axios'
import { makeUseAxios } from 'axios-hooks'

const axiosClient = axiosBase.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  validateStatus: function (status) {
    return true
  },
})

const axiosFormDataClient = axiosBase.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
  },
  validateStatus: function (status) {
    return true
  },
})

const useAxios = makeUseAxios({
  axios: axiosClient,
})

export { axiosClient, axiosFormDataClient, useAxios }
