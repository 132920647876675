import { env } from './env'

type Target = 'auth' | 'api'

export const fullUrl = (target: Target = 'auth') => {
  if (target === 'api') {
    return `${env.API_PROTCOL}://${env.API_DOMAIN}:${env.API_PORT}/api/v1`
  }

  if (typeof window === 'undefined' && target === 'auth') {
    return `${env.AUTH_PROTCOL}://${env.AUTH_DOMAIN}:${env.AUTH_PORT}/api/v1`
  } else if (target === 'auth') {
    const subdomain = window.location.host.split('.')[0]
    return `${env.AUTH_PROTCOL}://${subdomain}.${env.AUTH_DOMAIN}:${env.AUTH_PORT}/api/v1`
  }
}
